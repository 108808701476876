.base-layout-wrapper{
    height: 100vh;

    .alerts-wrapper{
        padding-top: 70px;
        position: fixed;
        z-index: 98;
        width: 100%;
        background: $lightgrayshade;
        right: 0;
        left: 0;
    }

    .page-content{
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 70px 0 20px 0;
        min-height: 100vh;
    }
}