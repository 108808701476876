.location {
  width: 100%;
  padding: 15px 0 5px 0;

  .categorised-search .search-with-btn {
    .search-input {
      height: 40px;
      border-radius: unset;
      border: 1px solid #696969;
      text-indent: 10px;
    }

    .search-input::placeholder {
      @include common-input-text-style($lightgray);
      opacity: 1;
      /* Firefox */
    }
  }
}

.location-error {
  .categorised-search .search-with-btn {
    .search-input {
      border: 1px solid $err-red;
    }

    .search-input::placeholder {
      @include common-input-text-style($err-red);
      opacity: 1;
    }
  }
}