.product-card-item {
  .card-block {
    padding: 5px 10px 10px 10px !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: unset !important;

    .product-title-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .appplied-product-icon {
      width: 16px;
      height: 16px;
    }

    .card-title {
      margin: 0 !important;
      color: #151515;
      font-size: 16px;
      line-height: 32px;
    }

    .popover-content:has(.product-title-tooltip) {
      color: #fff;
      left: 0;
      margin: auto;
      bottom: 100%;

      & .popover-arrow {
        border-color: #000 transparent transparent transparent;
      }
    }

    .brand-wrapper {
      display: flex;
      justify-content: space-between;

      .org-logo {
        width: 30px;
        height: 31px;
        margin-right: 5px;
      }
    }

    .brand {
      color: $black;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      .org-name {
        padding-right: 10px;
      }

      .brand-name {
        padding-left: 10px;
        border-left: 1px solid $darkgray;
      }
    }

    .for-project {
      display: none;
    }
  }

  .image-wrap {
    position: relative;
    min-height: 200px;

    img {
      cursor: pointer;
      height: 220px;
      object-fit: cover;
    }

    .overlay-hover {
      position: absolute;
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;
      opacity: 0;
      z-index: 10;
      @include transition(all 0.5s ease);

      .action-button {
        @include background-opacity($white, 0.8);
        color: $black;
        display: block;
        width: 100%;
        @include calc(height, "50% - 5px");
        text-align: center;
        position: relative;
        margin: 0 0 10px;
        cursor: pointer;

        span {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          @include translate(-50%, -50%);
        }

        // &:hover {
        //   @include background-opacity($black, 0.85);
        // }
        .action-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }

        .action-button-icon{
          width: 18px;
          height: 18px;
          filter: invert(0%) sepia(4%) saturate(1554%) hue-rotate(318deg) brightness(89%) contrast(84%);
        }

        &:hover {
          color: $white !important;
          background-color: $black !important;

          .action-button-icon{
            filter: invert(100%) sepia(1%) saturate(21%) hue-rotate(154deg) brightness(105%) contrast(100%);
          }
        }
      }

      .action-button-disabled {
        opacity: 0.5;
        pointer-events: none;

        &:hover {
          @include background-opacity($black, 0.6);
        }
      }

      &.overlay-secondary{
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .action-button{
          @include background-opacity($white, 1);
        }
      }
    }
  }

  .categories-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .category-row {
      display: flex;
      gap: 5px;
      align-items: center;

      img {
        height: 22px;
        width: 22px;
        object-fit: contain;
      }

      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #696969;
        margin: 0;
      }
    }
  }

  .sustainibility-pillers-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2px;
    row-gap: 8px;

    img {
      width: 22px;
      height: 22px;
    }
  }

  .card-price {
    color: $black !important;
  }

  &:hover {
    .overlay-hover {
      opacity: 1 !important;
    }
  }

  &.project-page-item {
    .for-project {
      display: block;
    }

    .card-price {
      text-align: end;
    }

    .image-wrap {
      height: 120px;

      img {
        height: 100%;
      }
    }

    .card-block {
      padding: 5px 5px 10px 10px !important;
      gap: 6px;

      .card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sustainibility-pillers-wrapper {
      img {
        width: 16px;
        height: 16px;
      }
    }

    .categories-wrapper {
      display: none !important;
    }

    .brand-wrapper {
      .card-price {
        display: none;
      }
    }
  }
}
