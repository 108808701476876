.bimmatch-popover {
  display: inline-block;
  position: relative;

  /* Positioning Content Main */
  .popover-content {
    border: 1px solid $gray;
    position: absolute;
    min-width: 100px;
    text-align: center;
    transition: opacity 0.3s;
    border-radius: 2px;
    padding: 12px 16px;
    background: $white;
    z-index: 1000;
    background-clip: padding-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    // white-space: nowrap
  }

  //---- Positioning -------------------

  /* Content Top */
  .popover-content.popover-content-top {
    bottom: calc(125% + 2px);
    left: 50%;
    margin-left: -61px;
  }

  .popover-content.popover-content-top .popover-arrow {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $gray transparent transparent transparent;
  }

  /* Content Right */
  .popover-content.popover-content-right {
    left: calc(100% + 2px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .popover-content.popover-content-right .popover-arrow {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $gray transparent transparent;
  }

  /* Content Bottom */
  .popover-content.popover-content-bottom {
    top: calc(135% + 2px);
    left: 50%;
    margin-left: -61px;
    // margin-left: -144%;
  }

  .popover-content.popover-content-bottom.popover-arrow-hidden {
    top: calc(100% + 2px);
  }

  .popover-content.popover-content-bottom .popover-arrow {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $gray transparent;
  }

  /* Content Left */
  .popover-content.popover-content-left {
    left: auto;
    right: calc(100% + 2px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .popover-content.popover-content-left .popover-arrow {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent $gray;
  }
}

// theme black
.bimmatch-popover-black {
  .popover-content {
    border: unset;
    background-color: $black;
    color: $white;
  }

  .popover-content.popover-content-top .popover-arrow {
    border-color: $black transparent transparent transparent;
  }

  .popover-content.popover-content-right .popover-arrow {
    border-color: transparent $black transparent transparent;
  }

  .popover-content.popover-content-bottom .popover-arrow {
    border-color: transparent transparent $black transparent;
  }

  .popover-content.popover-content-left .popover-arrow {
    border-color: transparent transparent transparent $black;
  }
}

//----Old Arrow Code ---------

// .popover-arrow {
//   position: absolute;
//   z-index: 2;
//   display: block;
//   width: 16px;
//   height: 16px;
//   overflow: hidden;
//   background: 0 0;
//   pointer-events: none;
//   margin: 0;
//   margin-left: calc(6px * -1);
//   padding: 0;

//   .popover-arrow-content {
//     box-shadow: 3px 3px 7px #00000012;
//     transform: translateY(0px) translateX(0px) rotate(45deg);
//     //    background-color: linear-gradient(to right bottom, rgba(0, 0, 0, .65), rgba(0, 0, 0, .75));
//     position: absolute;
//     inset: 0;
//     display: block;
//     width: 11.3137085px;
//     height: 11.3137085px;
//     margin: auto;
//     background-color: #fff;
//     content: "";
//     pointer-events: auto;
//     // border-radius: 0 0 2px;
//   }
// }