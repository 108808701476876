@keyframes appear {
  0% {
    opacity: 0;
  }
}

.bimmatch-modal {
  width: 100%;
  height: 100%;
  display: contents;

  .grey-screen {
    position: fixed;
    inset: 0;
    z-index: 1001;
    height: 100%;
    background-color: #00000073;
  }

  // .modal-wrap {
  //   position: fixed;
  //   inset: 0;
  //   overflow: auto;
  //   outline: 0;
  //   z-index: 1000;
  //   display: none;
  // }

  .modal-container {
    z-index: 1002;
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: appear 200ms ease-in 1;
    max-height: 90%;
    overflow: hidden;

    .bimmatch-modal-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid $lightgrayshade;
      padding: 10px 15px;

      .modal-title {
        font-family: $barlow;
        font-style: normal;
        font-weight: 275;
        font-size: 32px;
        line-height: 38px;
        color: $black;
      }

      .close-icon {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }

    .bimmatch-modal-body {
      margin-bottom: 10px;
      margin-top: 10px;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    .bimmatch-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px solid $lightgrayshade;
      padding: 10px 15px;

      .modal-button-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .bimmatch-button {
        margin-left: 10px;
      }
    }

    .extra-message {
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: $mid-gray;
      margin-left: 17px;
    }

    .bimmatch-modal-error {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      .error-icon {
        height: 70px;
        width: 70px;
      }
    }


    .bimmatch-modal-surprised {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 68px;
        height: 68px;
        margin: 50px auto;
      }
    }
  }

  .suggest-us {
    width: 100%;
    font-family: $opensans;
    font-style: normal;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}

.bimmatch-modal-extra-large {
  .modal-container {
    width: 940px;
  }
}

.bimmatch-modal-medium {
  .modal-container {
    width: 500px;
  }
}



.bimmatch-modal-large {
  .modal-container {
    width: 650px;
  }
}

.bimmatch-modal-close {
  display: none;
}

.bimmatch-modal-open {
  display: block;
}