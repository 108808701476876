#inactivity-logout-popup-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    .modal-body-wrapper{
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        img{
            width: 72px;
            height: 72px;
            flex: 1;
        }
    }
    &.show{
        display: block;
        overflow: hidden;
    }
    &.hide{
        display: none;
    }
}