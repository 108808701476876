.bimmatch-tooltip {
  display: inline-block;
  position: relative;

  /* Positioning Content Main */
  .tooltip-content {
    width: fit-content;
    text-align: center;
    transition: opacity 0.3s;
    border-radius: 2px;
    padding: 12px 16px;
    z-index: 1000;
    background-clip: padding-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    white-space: normal;

    background-color: $black;
    color: $white;
    margin: auto;
    max-width: 312px;
    width: max-content;
    text-align: start;

    margin-left: 0 !important;

    &.auto-width-content{
      max-width: none;
    }
  }

  // .tooltip-content.tooltip-content-top {
  //   margin-bottom: 10px;
  // }

  // .tooltip-content.tooltip-content-right {
  //   margin-left: 10px;
  // }

  // .tooltip-content.tooltip-content-bottom {
  //   margin-top: 10px;
  // }

  // .tooltip-content.tooltip-content-left {
  //   margin-right: 10px;
  // }

  //---- Positioning -------------------

  .tooltip-content .tooltip-arrow {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }

  .tooltip-content.tooltip-content-top .tooltip-arrow {
    border-color: $black transparent transparent transparent;
  }

  .tooltip-content.tooltip-content-right .tooltip-arrow {
    border-color: transparent $black transparent transparent;
  }

  .tooltip-content.tooltip-content-bottom .tooltip-arrow {
    border-color: transparent transparent $black transparent;
  }

  .tooltip-content.tooltip-content-left .tooltip-arrow {
    border-color: transparent transparent transparent $black;
  }
}
