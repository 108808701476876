.no-data-view {
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-data-img {
        height: 50px;
        width: 63px;
    }

    .no-data-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: $lightgray;
        margin-top: 5px;
    }
}